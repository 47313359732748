import { Link } from 'gatsby'
import * as React from 'react'

const NotFoundPage = () => (
  <div style={{ padding: 40 }}>
    <h1 style={{ fontSize: 30, paddingBottom: 30 }}>NOT FOUND</h1>
    <p style={{ paddingBottom: 20 }}>You just hit a route that doesn&#39;t exist... the sadness.</p>
    <Link to="/">Home</Link>
  </div>
)

export default NotFoundPage
